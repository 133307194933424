import { useState } from "react";
import classNames from "classnames/bind";
import styles from "./App.module.css";
import Header from "./components/Header/Header";
import Accordion from "./components/Accordion/Accordion";
import headerImg from "./assets/header-img.png";
import logoBeterDichtbij from "./assets/logo-beterdichtbij.png";
import logoPlanJeZorgOnline from "./assets/logo-planjezorg-online.jpg";
import Form from "./components/Form/Form";
import qrCode from "./assets/qr-code-full.png";
import pdf from "./assets/FAQ.pdf";

const cx = classNames.bind(styles);

function App() {
  const [showEndScreen, setShowEndScreen] = useState(false);

  const [video1Completed, setVideo1Completed] = useState(false);
  const [video2Completed, setVideo2Completed] = useState(false);
  const [video3Completed, setVideo3Completed] = useState(false);

  const [userName, setUserName] = useState("");

  return !showEndScreen ? (
    <div className={cx("base")}>
      <Header />
      <div className={cx("headerImg")}>
        <img src={headerImg} alt="Banner on the top" />
      </div>

      {/* INTRO TEXT ------------------ */}
      <div className={cx("container")}>
        <h1>Obesitas chirurgie</h1>
        <p>
          Welkom bij obesitas centrum Beverwijk. Binnenkort ondergaat u een
          maagverkleining. In de video's hieronder leert u meer over deze
          operatie. Wij willen zeker weten dat u alle video's heeft bekeken. U
          moet daarom een QR code genereren. Deze code kunt u alleen genereren
          als u alle video's hebt bekeken.
        </p>
      </div>

      {/* ACCORDIONS ------------------ */}
      <div className={cx("container")}>
        <Accordion
          number={1}
          title={"Voor mijn operatie"}
          completed={video1Completed}
        >
          <video controls autoPlay onEnded={() => setVideo1Completed(true)}>
            <source
              src="https://player.vimeo.com/progressive_redirect/playback/736148384/rendition/1080p/file.mp4?loc=external&signature=6a98fd975d187a05f7edf86b06d07b43f974f942fa4856930f95c47bd78ff8cd"
              type="video/mp4"
            />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </Accordion>
        <Accordion
          number={2}
          title={"De operatie"}
          completed={video2Completed}
          // disabled={!video1Completed}
        >
          <video controls autoPlay onEnded={() => setVideo2Completed(true)}>
            <source
              src="https://player.vimeo.com/progressive_redirect/playback/736148483/rendition/1080p/file.mp4?loc=external&signature=5adcc0f81654181fadbfb2713041d856c7026539d6fa1978499d1f98547e44ec"
              type="video/mp4"
            />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </Accordion>
        <Accordion
          number={3}
          title={"Na mijn operatie"}
          completed={video3Completed}
          // disabled={!video2Completed}
        >
          <video controls autoPlay onEnded={() => setVideo3Completed(true)}>
            <source
              src="https://player.vimeo.com/progressive_redirect/playback/736148582/rendition/1080p/file.mp4?loc=external&signature=f519d63a2f33dc89bebfb98520279f7450aa710cabf2955381d00d726df8056a"
              type="video/mp4"
            />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </Accordion>
      </div>

      {/* VRAGEN? ------------------ */}
      <div className={cx("container")}>
        <h3>Heeft u nog vragen?</h3>
        <p>
          Heeft u na het bekijken van de video's toch nog vragen? Chat met een
          medewerker van BeterDichtbij.
        </p>

        <p className={cx("mt-2")}>Voor medische vragen:</p>
        <a
          href="https://www.beterdichtbij.nl/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>
            <img src={logoBeterDichtbij} alt="Beter dichtbij" />
          </button>
        </a>
        {/* <p className={cx("mt-1")}>voor het plannen van uw operatiedatum:</p>
        <a
          href="https://www.planjezorg.online/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>
            <img src={logoPlanJeZorgOnline} alt="Plan je zorg" />
          </button>
        </a> */}
        <h4>Bij noodgevallen bel direct: 0251 26 5555</h4>
      </div>

      {/* QR CODE GENERATION ------------------ */}
      <div className={cx("container")}>
        <h3>Veelgestelde vragen</h3>
        <p>
          Download{" "}
          <a href={pdf} type="application/pdf" download="FAQ">
            hier
          </a>{" "}
          het document met veelgestelde vragen.
        </p>

        <h3>QR code genereren</h3>
        <p>
          Als u alle animaties hebt bekeken en begrijpt wat u te wachten staat
          bij deze behandeling, kunt u hieronder uw gegevens invullen om zo een
          persoonlijke QR code te genereren. De gegevens die u invult worden
          niet opgeslagen, maar er wordt een persoonlijke QR code gemaakt die u
          op de dag van de behandeling laat zien.
        </p>

        <Form
          disabled={!video3Completed}
          showEndScreen={userName => {
            setShowEndScreen(true);
            setUserName(userName);
          }}
        />
      </div>
    </div>
  ) : (
    <div className={cx("base")}>
      <Header />
      <div className={cx("headerImg")}>
        <img src={headerImg} alt="Banner" />
      </div>

      {/* INTRO TEXT ------------------ */}
      <div className={cx("container")}>
        <h3>Beste {userName}</h3>
        <p>
          Hieronder vindt u uw persoonlijke QR code. Laat deze zien op de dag
          van de behandeling.
        </p>
      </div>

      {/* VRAGEN? ------------------ */}
      <div className={cx("container")}>
        <h3>Heeft u nog vragen?</h3>
        <p>
          Heeft u na het bekijken van de video's toch nog vragen? Chat met een
          medewerker van BeterDichtbij of Planjezorg.
        </p>

        <p className={cx("mt-2")}>Voor medische vragen:</p>
        <button>
          <img src={logoBeterDichtbij} alt="Beter dichtbij logo" />
        </button>
        <p className={cx("mt-1")}>Voor het plannen van uw operatiedatum:</p>
        <button>
          <img src={logoPlanJeZorgOnline} alt="Plan je zorg logo" />
        </button>
      </div>

      <div className={cx("container", "qr-code")}>
        <img src={qrCode} alt="QR code" />
      </div>
    </div>
  );
}

export default App;
