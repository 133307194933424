import * as React from "react";
import classNames from "classnames/bind";
import styles from "./Header.module.css";
import logo from "./Logo-RKZ.svg";

const cx = classNames.bind(styles);

const Header = (props) => {
  return (
    <div className={cx("base")}>
      <img src={logo} />
    </div>
  );
};

export default Header;
