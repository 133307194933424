import { React, useRef, useState, useMemo } from "react";
import classNames from "classnames/bind";
import styles from "./Form.module.css";
import checkmark from "./checkmark.svg";
import qrCode from "./qr-code.svg";
import checkCircle from "./check-circle.svg";
import spinner from "./loader.svg";

const cx = classNames.bind(styles);

const Form = ({ disabled, showEndScreen }) => {
  const form = useRef(null);

  const [formState, setFormState] = useState("disabled"); // "disabled" | "enabled" | "loading" | "submitted" | "error"
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const handleCheckboxChange = (event) => {
    if (!disabled) {
      if (event.target.checked === false) {
        setFormState("disabled");
      } else {
        setFormState("enabled");
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (formState !== "submitted") {
      setFormState("loading");
      const requestBody = {
        name: userName,
        email_address: userEmail,
        token: btoa(userName + userEmail),
      };

      fetch("api/save", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(requestBody),
      })
        .then(() => {
          setFormState("submitted");
        })
        .catch((error) => console.error(error));
    } else {
      showEndScreen(userName);
    }
  };

  const buttonContent = useMemo(() => {
    switch (formState) {
      case "loading":
        return (
          <>
            <img src={spinner} className={cx("loader")} alt="spinner" />
          </>
        );
      case "error":
        return (
          <>
            Er is iets mis gegaan{" "}
            <img src={qrCode} alt="er is iets mis gegaan" />
          </>
        );
      case "submitted":
        return (
          <>
            Uw QR-code is succesvol verstuurd{" "}
            <img src={checkCircle} alt="QR code succesvol verstuurd" />
          </>
        );
      default:
        return (
          <>
            Genereer uw persoonlijke QR-code{" "}
            <img src={qrCode} alt="Genereer uw QR code" />
          </>
        );
    }
  }, [formState]);

  return (
    <form className={cx("base", formState)} ref={form} onSubmit={handleSubmit}>
      <label className={cx("checkbox")}>
        <input
          type="checkbox"
          checked={formState !== "disabled" && !disabled}
          onChange={handleCheckboxChange}
        />
        <span>
          <img src={checkmark} alt="checkmark" />
        </span>
        Ik heb alle animaties bekeken
      </label>

      <label>
        Uw naam
        <input
          value={userName}
          onChange={(event) => setUserName(event.target.value)}
          type="text"
          name="name"
          placeholder="Uw naam"
          disabled={formState === "disabled" || formState === "submitted"}
          required
        />
      </label>

      <label>
        Uw e-mailadres
        <input
          value={userEmail}
          onChange={(event) => setUserEmail(event.target.value)}
          type="email"
          name="email"
          placeholder="Uw e-mailadres"
          disabled={formState === "disabled" || formState === "submitted"}
          required
        />
      </label>
      <button
        className={cx(formState)}
        type="submit"
        disabled={
          formState === "disabled" || userName === "" || userEmail === ""
        }
      >
        {buttonContent}
      </button>
    </form>
  );
};

export default Form;
