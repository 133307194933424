import * as React from "react";
import classNames from "classnames/bind";
import styles from "./Accordion.module.css";
import { useState } from "react";
import chevron from "./chevron-down.svg";
import greenCheck from "./green-check-mark.svg";

const cx = classNames.bind(styles);

const Accordion = ({ title, number, children, completed, disabled }) => {
  const [showContent, setShowContent] = useState(false);
  return (
    <div
      className={cx("base", { disabled })}
      onClick={() => {
        if (!disabled) {
          setShowContent((prevState) => !prevState);
        }
      }}
    >
      <header>
        <h1>
          <span>Deel {number}:</span> {title}
        </h1>
        <div className={cx("icons")}>
          {completed && <img src={greenCheck} />}
          <img src={chevron} className={cx({ open: showContent })} />
        </div>
      </header>
      {showContent && <div className={cx("content")}>{children}</div>}
    </div>
  );
};

export default Accordion;
